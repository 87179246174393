import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { Section, Container, Cols, Col } from '@elwell/blocko';

import { vars } from '../lib/theme';
import { LESSONS_QUERY } from '../lib/graphql';

import Button from './Button';
import LoadingSpinner from './LoadingSpinner';
import LoadingError from './LoadingError';
// import LessonList from './LessonList';
import LessonListCarousel from './LessonListCarousel';
// import LessonCarousel from './LessonCarousel';

const SectionHomeLessons = () => {
  const { data, error, loading } = useQuery(LESSONS_QUERY, { variables: { first: 9 } });
  // console.log(data, error, loading);
  if (loading) return <LoadingSpinner />;
  if (error) return <LoadingError error={error} />;

  const { lessons } = data;
  if (!lessons) return <LoadingError />;

  // return <LessonCarousel lessons={lessons.nodes} />;

  return (
    <Section space={1} bg={vars.colors.grey.offwhite}>
      <Container width={vars.break.xl}>
        <Container
          css={`
            margin-bottom: ${vars.spacing[4]};
          `}
          space={1}
          width={vars.break.md}
          textAlign="center"
        >
          <h2>Latest Lessons</h2>
          <p>
            Our exclusive guitar lessons cover a huge range of topics, and packed full of tips and insights to help you become an awesome guitarist! We launch new lessons every
            single week so there's always something new to learn.
          </p>
        </Container>
        {lessons.nodes && <LessonListCarousel lessons={lessons.nodes} />}
        {/* <LessonList lessons={lessons.nodes} /> */}

        <Container space={1} width={vars.break.md} textAlign="center">
          <Link href="/lessons">
            <a>
              <Button
                css={`
                  margin-top: ${vars.spacing[4]};
                `}
              >
                Browse all lessons
              </Button>
            </a>
          </Link>
        </Container>
      </Container>
    </Section>
  );
};

export default SectionHomeLessons;

// Styles

const Inner = styled('div')`
  padding: ${vars.spacing[4]} 0;
  text-align: center;
`;

const H3 = styled('h3')`
  /* margin-bottom: ${vars.spacing[2]}; */
`;
