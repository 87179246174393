import React from 'react';
import Head from 'next/head';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import { withApollo } from '../lib/apollo';
import { vars } from '../lib/theme';
import { Container } from '../lib/styles';

import HeadMeta from '../components/HeadMeta';
import Layout from '../components/Layout';
import SectionHomeHero from '../components/SectionHomeHero';
import SectionHomeUspsNew from '../components/SectionHomeUspsNew';
import SectionHomeUsps from '../components/SectionHomeUsps';
import SectionHomeIntro from '../components/SectionHomeIntro';
// import SectionHomePlatform from '../components/SectionHomePlatform';
// import SectionHomeSavings from '../components/SectionHomeSavings';
// import SectionSoloComp from '../components/SectionSoloComp';
import SectionNextMasterclass from '../components/SectionNextMasterclass';
import SectionCta from '../components/SectionCta';
import SectionQuote from '../components/SectionQuote';
import SectionHomeLessons from '../components/SectionHomeLessons';
import SectionTestimonials from '../components/SectionTestimonials';
import CurrentOffer from '../components/CurrentOffer';

// import MailingPopup from '../components/MailingPopup';

const Home = () => {
  return (
    <Layout>
      <MessengerCustomerChat pageId="106384141091883" appId="1678638095724206" htmlRef="fb-customerchat" greetingDialogDisplay="fade" />
      <HeadMeta title="Learn To Play Guitar - School of McRock Online Guitar Lessons" />
      {/* <MailingPopup /> */}
      <SectionHomeHero />
      {/* <SectionSoloComp /> */}
      <SectionHomeUspsNew />
      <SectionHomeLessons />
      <SectionHomeIntro />
      <SectionCta heading="Claim your free trial" body="Join now for full instant access and pay nothing for 14 days" link="/join" buttonText="Get started" />
      <SectionTestimonials />
      <SectionNextMasterclass />
      <SectionCta
        heading="Unlock your potential"
        body="Stop playing the same licks over and over. Unlock your potential and become a better guitar player."
        link="/join"
        buttonText="Create your account"
      />
      <SectionHomeUsps />
      <Container>
        <CurrentOffer
          css={css`
            margin-top: ${vars.spacing[3]};
            margin-bottom: ${vars.spacing[4]};
          `}
        />
      </Container>
      {/* <SectionHomePlatform /> */}
      <SectionCta
        heading="Is your playing stuck in a rut?"
        body="Our lessons are fun, interesting and jam-packed with tips and exercises that will have you playing like the pros in no time!"
        link="/join"
        buttonText="Start your journey"
      />
      <SectionQuote
        quote="I created this platform for the modern guitarist. Most guitar tuition follows a specific formula and I want to break out of that and offer a more fun, direct way of teaching people my approach to playing."
        author="Thomas McRocklin"
        img="/static/tmc-320.jpg"
      />
      {/* <SectionHomeSavings /> */}
    </Layout>
  );
};

export default withApollo(Home, { ssr: false });
