import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import { vars, IconArrowRight } from '../lib/theme';
import { Container } from '../lib/styles';

import Button from './Button';
import ModalHowItWorks from './ModalHowItWorks';

const SectionHomeHero = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <Section>
      <VideoBg>
        <video loop muted autoPlay playsInline>
          <source src="/static/video-home-hero-1-720.mp4" type="video/mp4" />
        </video>
      </VideoBg>
      <Container>
        <Inner>
          <Content>
            <Title>
              Learn to play guitar{' '}
              <span>better than you ever thought possible</span>
            </Title>
            <Text>
              Is your guitar playing stuck in a rut? Join our school and learn
              all of the professional tips, techniques and insights you need to{' '}
              <b>unlock your full potential</b>.
            </Text>
            {/* <Link href="/join">
              <a>
                <Button theme="success">Get full access - free for 14 days</Button>
              </a>
            </Link> */}
            <Button theme="success" onClick={() => setIsModalOpen(true)}>
              How It Works{' '}
              <IconArrowRight
                css={css`
                  position: relative;
                  left: 1em;
                `}
              />
            </Button>
            <ModalHowItWorks isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
          </Content>
        </Inner>
      </Container>
      <div
        css={css`
          display: block;
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 4vw;
          z-index: 1;
        `}
      >
        <svg
          css={css`
            height: 100% !important;
            width: 100% !important;
            display: block;
          `}
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          viewBox="0 0 40 40"
        >
          {/* <polygon
            css={css`
              fill: #fff;
            `}
            points="0,40 40,0 40,40"
          /> */}
          <polygon
            css={css`
              fill: #fff;
            `}
            points="0,40 40,40 40,0"
          />
        </svg>
      </div>
    </Section>
  );
};

export default SectionHomeHero;

// Styles

const Section = styled('section')`
  background-color: ${vars.colors.black};
  position: relative;
  padding-top: 70px;
  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
  }
  overflow: hidden;
  /* box-shadow: ${vars.shadows[0]}; */
`;

const VideoBg = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${vars.colors.black};

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0.5;
  }
`;

const Inner = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  min-height: 80vh;
  align-items: center;
  padding: ${vars.spacing[1]} 0 ${vars.spacing[2]} 0;
  @media screen and (min-width: ${vars.break.xl}) {
    min-height: 50vw;
  }
`;

const Content = styled('div')`
  display: block;
  /* flex: 0 1 700px; */
  position: relative;
  z-index: 1;
`;

const Title = styled('h1')`
  color: ${vars.colors.white};
  max-width: ${vars.break.md};
`;

const Text = styled('p')`
  margin-bottom: ${vars.spacing[3]};
  color: ${vars.colors.grey.offwhite};
  max-width: ${vars.break.sm};
`;
