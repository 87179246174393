import React from 'react';
import styled, { css } from 'styled-components';

import { vars } from '../lib/theme';

const OfferBox = (props) => {
  return (
    <Root className={props.className}>
      <Box bg={props.bg} height={props.height}>
        <Inner>{props.children}</Inner>
      </Box>
    </Root>
  );
};

export default OfferBox;

// STYLES
const Root = styled('div')``;

const Box = styled('div')`
  border-radius: ${vars.borderRadius[1]};
  background-color: ${vars.colors.secondary.darker};
  position: relative;
  /* padding-top: ${({ height }) => height || '62%'}; */
  ${({ bg }) =>
    bg &&
    css`
      background-image: url(${bg});
    `}
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  line-height: 1.4;
  font-weight: 500;
  color: ${vars.colors.grey.lightest};
  transition: all 0.2s ease-in-out;
  display:flex;
  justify-content: flex-start;
  align-items: center;
  /* min-height: 40vh; */
  overflow:hidden;
  &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.6;
      background: linear-gradient(172deg, ${vars.colors.secondary.darkest}, ${vars.colors.black});
      @media screen and (min-width: ${vars.break.md}) {
        display: none;
  }
    }
  h3,
  h4 {
    color: ${vars.colors.white};
  }
`;

const Inner = styled('div')`
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0; */
  /* flex: 0 1 ${vars.break.sm}; */
  background: linear-gradient(to right, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0));
  flex: 0 1 550px;
  position: relative;
  z-index: 1;
  padding: ${vars.spacing[2]};
  text-align: center;
  @media screen and (min-width: ${vars.break.sm}) {
    text-align: left;
    padding: ${vars.spacing[3]};
  }
  @media screen and (min-width: ${vars.break.md}) {
    /* padding: ${vars.spacing[4]}; */
  }
`;
