import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import { vars, IconLdquo } from '../lib/theme';
import { Container } from '../lib/styles';

const SectionQuote = ({ quote, author, img }) => {
  return (
    <Section>
      <Container maxWidth={vars.break.md}>
        <Inner>
          {img && (
            <AvatarWrap>
              <Ring>
                <Img src={img} alt={`${author} avatar`} loading="lazy" />
              </Ring>
            </AvatarWrap>
          )}
          {quote && (
            <Quote>
              <Ldquo>
                <IconLdquo size={40} />
              </Ldquo>
              {quote}&rdquo;{author && <Cite>- {author}</Cite>}
            </Quote>
          )}
        </Inner>
      </Container>
    </Section>
  );
};

export default SectionQuote;

// Styles

const Section = styled('section')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: ${vars.spacing[4]} 0;
  @media screen and (min-width: ${vars.break.md}) {
    text-align: left;
  }
`;

const Inner = styled('div')`
  flex: 0 0 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: ${vars.break.md};
  margin: 0 auto;
  @media screen and (min-width: ${vars.break.md}) {
    flex-wrap: nowrap;
  }
`;

const AvatarWrap = styled('div')`
  flex: 0 0 160px;
  height: 160px;
  margin: 0 auto ${vars.spacing[3]} auto;
  @media screen and (min-width: ${vars.break.md}) {
    margin: 0 ${vars.spacing[4]} 0 0;
  }
`;

const Ring = styled('div')`
  position: relative;
  &:before {
    display: block;
    content: '';
    position: absolute;
    border-radius: 50%;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    z-index: -1;
    background-color: white;
  }
  &:after {
    display: block;
    content: '';
    position: absolute;
    border-radius: 50%;
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
    z-index: -2;
    background: linear-gradient(15deg, ${vars.colors.tertiary.mid}, ${vars.colors.primary.mid});
  }
`;

const Quote = styled('blockquote')`
  margin: 0;
  flex: 0 0 100%;
  font-size: 1.2rem;
  font-style: italic;
  flex: 1 1 auto;
  position: relative;
`;

const Cite = styled('cite')`
  display: block;
  margin-top: ${vars.spacing[1]};
  font-style: normal;
  font-weight: 700;
  color: ${vars.colors.primary.mid};
  text-align: right;
  font-size: 1rem;
`;

const Img = styled('img')`
  border-radius: 50%;
  object-fit: cover;
  object-position: center center;
  background-color: ${vars.colors.black};
`;

const Ldquo = styled('span')`
  position: absolute;
  top: -0.85em;
  left: -0.9em;
  z-index: -1;
  opacity: 0.1;
`;
