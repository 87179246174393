import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import { vars } from '../lib/theme';
import { Container } from '../lib/styles';

import Button from './Button';

const SectionHomeIntro = () => {
  return (
    <Root>
      <Container
        css={css`
          @media screen and (max-width: 961px) {
            padding: 0;
          }
        `}
      >
        <Inner>
          <Image>
            <ImageWrap>
              <img src="https://api.schoolofmcrock.com/content/uploads/2020/02/mcrock-avatar.jpg" />
            </ImageWrap>
          </Image>
          <Content>
            <Heading>I'm Thomas McRocklin.</Heading>
            <p>
              <b>I've been playing guitar since I was 4 years old - and I've been lucky enough to learn from some of the greatest guitar players in the world.</b>
            </p>
            <p>
              I've performed with so many amazing musicians - Joe Satriani, Zakk Wylde, Steve Lukather, Larry Mitchell, Stu Hamm, and of course my friend and mentor - Steve Vai.
            </p>
            <p>
              School of McRock is the place where I share everything I’ve learned from years of playing with these legendary guitarists - as well as my own insights and techniques.
            </p>
            <p>
              <Link href="/join">
                <a>
                  <b>I'm so confident you'll love it that I'll let you try it completely free for 14 days.</b>
                </a>
              </Link>
            </p>
            <Link href="/join">
              <a>
                <Button
                  css={css`
                    margin-bottom: ${vars.spacing[3]};
                  `}
                  theme="success"
                >
                  Join our family
                </Button>
              </a>
            </Link>
            <p>
              <Signature src="/static/signature.svg" alt="Thomas signature" />
            </p>
          </Content>
        </Inner>
      </Container>
    </Root>
  );
};

export default SectionHomeIntro;

// Styles

const Root = styled('section')`
  position: relative;
  @media screen and (min-width: ${vars.break.lg}) {
    padding: ${vars.spacing[3]} 0;
  }
`;

const Inner = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
`;

const Image = styled('div')`
  position: relative;
  flex: 0 0 100%;
  @media screen and (min-width: ${vars.break.lg}) {
    flex: 0 0 50%;
    padding-right: 2%;
  }
`;

const ImageWrap = styled('div')`
  @media screen and (min-width: ${vars.break.lg}) {
    border-radius: ${vars.borderRadius[1]};
    overflow: hidden;
    box-shadow: ${vars.shadows[0]};
  }
`;

const Content = styled('div')`
  position: relative;
  flex: 0 0 100%;
  padding: ${vars.spacing[2]};
  @media screen and (min-width: ${vars.break.lg}) {
    flex: 0 0 50%;
    /* order: 1; */
    padding: 0;
    padding-left: 2%;
  }
`;

const Heading = styled('h2')``;

const Signature = styled('img')`
  width: 100px;
`;
