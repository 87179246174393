import React from 'react';
import Link from 'next/link';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

import { vars } from '../lib/theme';
import { Container } from '../lib/styles';
import { LATEST_MASTERCLASS_QUERY } from '../lib/graphql';
import { getTimeRemaining } from '../lib/utils';

import CountdownClock from './CountdownClock';
import Button from './Button';

import useUser from './useUser';
import useCountdown from './useCountdown';

const SectionNextMasterclass = () => {
  const [date, setDate] = React.useState(null);
  const [day, setDay] = React.useState('');
  const [month, setMonth] = React.useState('');
  const [time, setTime] = React.useState('');

  const { data, error, loading } = useQuery(LATEST_MASTERCLASS_QUERY);

  const user = useUser();
  const countdown = useCountdown(date);

  const showTrial = !user?.hasHadTrial;

  React.useEffect(() => {
    if (data?.latestMasterclass) {
      const dateGmt = data?.latestMasterclass?.dateGmt;
      setDate(parseISO(dateGmt));
      setDay(format(parseISO(dateGmt), 'd'));
      setMonth(format(parseISO(dateGmt), 'MMMM'));
      setTime(format(parseISO(dateGmt), 'HH:mm'));
    }
  }, [data]);

  const isFuture = data?.latestMasterclass?.isFuture;

  if (!isFuture) return null;

  return (
    <Section>
      <Container>
        <Inner>
          <Left>
            <CountdownClock countdown={countdown} />
          </Left>
          <Right>
            <h2>Next Live Masterclass</h2>
            <p>Our members get exclusive access to weekly live video sessions where they can ask questions and get feedback on their playing.</p>
            <p>
              Our next masterclass goes live on
              <b>{` ${day} ${month} at ${time} GMT`}</b>.
            </p>
            {showTrial && (
              <>
                <p>Start your free trial now and join us!</p>
                <Link href="/join">
                  <a>
                    <Button theme="success">Join now</Button>
                  </a>
                </Link>
              </>
            )}
          </Right>
        </Inner>
      </Container>
    </Section>
  );
};

export default SectionNextMasterclass;

// Styles

const Section = styled('section')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: ${vars.spacing[4]} 0;
  background-color: ${vars.colors.white};
  background-size: cover;
  background-position: center;
`;

const Inner = styled('div')`
  margin: 0 auto;
  @media screen and (min-width: ${vars.break.md}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Left = styled('div')`
  margin-bottom: ${vars.spacing[3]};
  text-align: center;
  @media screen and (min-width: ${vars.break.md}) {
    margin-bottom: 0;
    float: left;
    width: 50%;
    padding-right: ${vars.spacing[2]};
  }
`;
const Right = styled('div')`
  @media screen and (min-width: ${vars.break.md}) {
    margin-top: 0;
    float: right;
    width: 50%;
    padding-left: ${vars.spacing[2]};
  }
`;
