import React from 'react';

import { vars } from '../lib/theme';

import useUser from './useUser';

import OfferBox from './OfferBox';

const CurrentOffer = ({ className }) => {
  return null;
  const user = useUser();

  let message = <p>Register or take a free trial of School of McRock during April to be entered into the prize draw!</p>;

  if (user) message = <p>Members are automatically entered into the prize draw!</p>;

  if (user && !user.hasActiveSubscription) message = <p>Sign up to School of McRock during April to be entered into the prize draw!</p>;

  return (
    <OfferBox className={className} bg="/static/bg-promo-friedman-be100.jpg">
      <h3>Win a Friedman BE-100 plugin</h3>
      {message}
    </OfferBox>
  );
};

export default CurrentOffer;
