import React from 'react';
import styled, { css } from 'styled-components';

import { vars } from '../lib/theme';

import LoadingSpinner from './LoadingSpinner';

const CountdownClock = ({ countdown }) => {
  const { total, days, hours, minutes, seconds } = countdown;

  if (!total)
    return (
      <LoadingSpinner
        css={css`
          min-height: 80px;
        `}
      />
    );

  if (total <= 0) return 'Please refresh the page';

  return (
    <Root>
      <Box>
        {days < 10 && '0'}
        {days ? days : '0'}
        <Label>Days</Label>
      </Box>
      <Box>
        {hours < 10 && '0'}
        {hours ? hours : '0'}
        <Label>Hours</Label>
      </Box>
      <Box>
        {minutes < 10 && '0'}
        {minutes ? minutes : '0'}
        <Label>Mins</Label>
      </Box>
      <Box>
        {seconds < 10 && '0'}
        {seconds ? seconds : '0'}
        <Label>Secs</Label>
      </Box>
    </Root>
  );
};

export default CountdownClock;

// Styles
const Root = styled('div')`
  display: inline-flex;
  border-radius: ${vars.borderRadius[2]};
  padding-left: ${vars.spacing[1]};
  padding-right: ${vars.spacing[1]};
  justify-content: center;
  box-shadow: ${vars.shadows[0]};
  transform: rotate(-1deg);
  background-color: ${vars.colors.primary.darkest};
  background: linear-gradient(15deg, ${vars.colors.primary.darkest}, ${vars.colors.primary.darker});
`;
const Box = styled('div')`
  flex: 0 0 60px;
  text-align: center;
  padding: ${vars.spacing[1]};
  color: ${vars.colors.white};
  font-family: ${vars.fonts.headings};
  font-weight: 800;
  font-size: 1.8rem;
`;

const Label = styled('div')`
  color: ${vars.colors.primary.offwhite};
  font-size: 1rem;
  letter-spacing: 0.04em;
  font-family: ${vars.fonts.headings};
  text-transform: uppercase;
  font-weight: 800;
`;
