import React from 'react';

import { getTimeRemaining } from '../lib/utils';

import useInterval from './useInterval';

const useCountdown = (date) => {
  const [countdown, setCountdown] = React.useState({});

  useInterval(() => {
    if (date) {
      const t = getTimeRemaining(date);
      setCountdown(t);
    }
  }, 1000);

  return countdown;
};

export default useCountdown;
