import React from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import styled from 'styled-components';

import { vars } from '../lib/theme';
import { Container } from '../lib/styles';

import Button from './Button';
import LoadingSpinner from './LoadingSpinner';

const Modal = dynamic(() => import('./Modal'), { ssr: false });
const VideoPlayer = dynamic(() => import('./VideoPlayer'), { ssr: false, loading: () => <LoadingSpinner /> });

const ModalHowItWorks = ({ isOpen, setIsOpen }) => {
  return (
    <Modal flush title="How It Works" isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Container flush maxWidth={vars.break.md}>
        <VideoPlayer videoType="youtube" videoId="p74krynuFSA" />
        <Inner>
          <Left>
            <h4>Ready to go?</h4>
            <p>Create an account to access all of my lessons and content - completely free for 14 days</p>
            <Link href="/join">
              <a>
                <Button theme="success" block>
                  Take a trial
                </Button>
              </a>
            </Link>
          </Left>
          <Right>
            <h4>Need a warm up?</h4>
            <p>Click here to receive your FREE guitar toolkit guaranteed to take your playing to the next level</p>
            <Link href="/free-guitar-toolkit">
              <a>
                <Button block>Get the toolkit</Button>
              </a>
            </Link>
          </Right>
        </Inner>
      </Container>
    </Modal>
  );
};

export default ModalHowItWorks;

// Styles

const Inner = styled('div')`
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
`;
const Left = styled('div')`
  flex: 0 0 100%;
  padding: ${vars.spacing[2]};
  display: flex;
  flex-direction: column;
  button {
    margin-top: auto;
  }
  @media screen and (min-width: ${vars.break.md}) {
    flex: 0 0 50%;
  }
`;
const Right = styled('div')`
  flex: 0 0 100%;

  padding: ${vars.spacing[2]};
  background-color: ${vars.colors.grey.offwhite};
  display: flex;
  flex-direction: column;
  button {
    margin-top: auto;
  }
  @media screen and (min-width: ${vars.break.md}) {
    flex: 0 0 50%;
  }
`;
