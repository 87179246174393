import React from 'react';
import styled, { css } from 'styled-components';
import Carousel from 'nuka-carousel';
import { Section, Container, Cols, Col } from '@elwell/blocko';
import useViewportSizes from 'use-viewport-sizes';

import { vars, IconArrowLeft, IconArrowRight } from '../lib/theme';

import LessonItemBoxInfo from './LessonItemBoxInfo';
import Button from './Button';

const LessonListCarousel = ({ lessons }) => {
  const [slidesToShow, setSlidesToShow] = React.useState(1);
  const [vpWidth, vpHeight, updateVpSizes] = useViewportSizes();

  React.useEffect(() => {
    updateVpSizes();
  }, []);
  React.useEffect(() => {
    let s = 1;
    if (vpWidth > 700) s = 2;
    if (vpWidth > 1200) s = 3;
    setSlidesToShow(s);
  }, [vpWidth]);

  return (
    <CarouselWrap>
      <Carousel
        css={css`
          padding-left: 40px;
          padding-right: 40px;
        `}
        slidesToShow={slidesToShow}
        cellSpacing={40}
        renderBottomCenterControls={null}
        renderCenterLeftControls={({ previousSlide }) => (
          <Button theme="textOnly" onClick={previousSlide}>
            <IconArrowLeft style={{ color: vars.colors.black }} size={40} />
          </Button>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <Button theme="textOnly" onClick={nextSlide}>
            <IconArrowRight style={{ color: vars.colors.black }} size={40} />
          </Button>
        )}
      >
        {lessons.map((lesson) => {
          return (
            <div key={lesson.id}>
              <LessonItemBoxInfo
                showDesc={false}
                {...lesson}
                css={css`
                  box-shadow: none;
                `}
              />
            </div>
          );
        })}
      </Carousel>
    </CarouselWrap>
  );
};

export default LessonListCarousel;

// Styles

const CarouselWrap = styled('div')`
  .slider-slide {
    outline: none;
    display: flex !important;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    & > * {
      flex: 0 0 100%;
    }
  }
`;
